<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <MatrizActasLista v-auth-acl="'gh-act-mat_aac-read'"></MatrizActasLista>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MatrizActas',
  components: {
    MatrizActasLista: () => import('../components/MatrizActasLista')
  },
  data: () => ({
    page: {
      title: 'MATRIZ DE ACTAS: ACUERDOS Y COMPROMISOS'
    },
    breadcrumbs: [
      {
        text: 'Actas',
        disabled: true
      },
      {
        text: 'Matriz AAC',
        disabled: false
      }
    ]
  }),
  methods: {
    open() {},
    close() {}
  }
};
</script>
