<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" height="60px">
      <template v-slot:item-right>
        <div class="d-flex pt-2" style="height: 40px; font-weight: 500;">
          <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="dateRangeText"
                label="Filtro por fechas"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateRange" range locale="es-es" :first-day-of-week="1"></v-date-picker>
          </v-menu>
        </div>
      </template>
    </BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3">
          <MatrizActaGeneral v-auth-acl="'gh-act-mis_actas-read'" :dateRange="dateRange"></MatrizActaGeneral>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import MatrizActaService from '../services/MatrizActaService';
import moment from 'moment';
export default {
  name: 'MatrizActas',
  components: {
    MatrizActaGeneral: () => import('../components/MatrizActaGeneral')
  },
  data: () => ({
    page: {
      title: 'LISTADO DE ACTAS'
    },
    breadcrumbs: [
      {
        text: 'Actas',
        disabled: true
      },
      {
        text: 'Mis Actas',
        disabled: false
      }
    ],
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateRangeText: `${moment().format('DD-MM-YYYY')} ~ ${moment().format('DD-MM-YYYY')}`,
    matrizActaService: null,
    showLoading: false
  }),
  watch: {
    async dateRange(val) {
      const fecha = [];
      val.forEach((element) => {
        fecha.push(
          element
            .split('-')
            .reverse()
            .join('-')
        );
      });
      if (fecha.length === 1) {
        this.dateRangeText = `${fecha[0]} ~ `;
      }
      if (fecha.length === 2) {
        const min = Date.parse(val[0]) < Date.parse(val[1]) ? fecha[0] : fecha[1];
        const max = Date.parse(val[0]) > Date.parse(val[1]) ? fecha[0] : fecha[1];
        this.dateRangeText = `${min} ~ ${max}`;
      }
    }
  },
  methods: {
    async intervaloFechas() {
      let currentYear = moment().year(); // año actual
      let currentMonth = moment().month(); // año actual
      let endMonthDays = moment([currentYear, currentMonth]).daysInMonth(); // número de días en el mes
      this.dateRange = [
        moment(currentYear + '-' + currentMonth + '-' + 1).format('YYYY-MM-DD'),
        moment(currentYear + '-' + currentMonth + '-' + endMonthDays).format('YYYY-MM-DD')
      ];
      this.dateRange = [
        moment([currentYear, currentMonth, 1]).format('YYYY-MM-DD'),
        moment([currentYear, currentMonth, endMonthDays]).format('YYYY-MM-DD')
      ];
    }
  },
  async created() {
    this.matrizActaService = this.$httpService(new MatrizActaService(), this);
    await this.intervaloFechas();
  }
};
</script>
